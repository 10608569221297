
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  props: ["data", "id", "orderDetailId"],
  setup(props, { emit }) {
    const reasonId = ref(props.id);
    const store = useStore();
    store.dispatch(Actions.DIFFERENCE_REASON_LIST);

    const differenceReasonList = computed(
      () => store.state.DifferenceReasonModule.differenceReason.value || []
    );

    const differenceReasonOptions = computed(() =>
      differenceReasonList.value.map((o) => {
        return { name: o.name, value: o.id };
      })
    );

    const differenceReason = ref(props.data);

    // props.data değiştiğinde differenceReason'ı güncelle
    watch(
      () => props.data,
      (newValue) => {
        differenceReason.value = newValue;
      },
      { deep: true }
    );

    const onChange = (args) => {
      if (args && args.value) {
        var model = {
          name: args.value.name,
          value: args.value.value,
          orderDetailId: props.orderDetailId,
        };
        emit("changeDifferenceReason", model);
      } else {
        emit("changeDifferenceReason", {
          orderDetailId: props.orderDetailId,
          value: null,
        });
      }
    };

    return {
      differenceReason,
      differenceReasonList,
      differenceReasonOptions,
      onChange,
      reasonId,
    };
  },
};
